import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Alert,
  Button,
  Grid,
  Typography,
  Card,
  TextField,
  InputAdornment,
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import ReactHtmlParser from 'react-html-parser';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { useForm } from 'react-hook-form';
import YearSelectBox from '../components/vehicle/YearSelectBox';
import useMediaQuery from '@mui/material/useMediaQuery';
import { fCurrencyEuro, fNumberFull } from '../utils/formatNumber';
import CharitySelectBox from '../components/vehicle/CharitySelectBox';

import { SubHeadline } from '../components/SubHeadline';
import { FormProvider } from '../components/hook-form';
import { ImageForm } from './Step1/ImageForm';
import { VehicleForm } from './Step1/VehicleForm';

const FormVehicle = ({
  addVehicle,
  setFormState,
  formState,
  setTempVehicle,
  dataformOptions,
  partner,
  step1Errors,
  step1Submitted,
  availableSubmissionYears,
  directSubmit,
  charityPartner,
  sumCharityAmount,
}) => {
  const methods = useForm({
    defaultValues: {
      images: [],
      payoutPrice:
        partner.dataformOptions.availablePayoutModes[0] === 'instant'
          ? partner.payoutPrices[
              partner.dataformOptions.availableVehicleClasses[0]
            ]
          : partner.payoutPricesNotInstant[
              partner.dataformOptions.availableVehicleClasses[0]
            ],
      vehicleClass: partner.dataformOptions.availableVehicleClasses[0],
    },
  });

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedVehicleClass, setSelectedVehicleClass] = useState(
    partner.dataformOptions.availableVehicleClasses[0]
  );
  const [selectedPayoutMode, setSelectedPayoutMode] = useState(
    partner.dataformOptions.availablePayoutModes[0]
  );
  const [selectedCharity, setSelectedCharity] = useState(null);
  const [vehiclePayoutPrice, setVehiclePayoutPrice] = useState(null);
  const [showMaxCharityAlert, setShowMaxCharityAlert] = useState(null);
  const [hasCharityPartner, setHasCharityPartner] = useState(false);
  const [charityAmount, setCharityAmount] = useState(null);
  const [maxCharityAmount, setMaxCharityAmount] = useState(300);

  const theme = useTheme();

  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));

  const vehiclePayoutPrices =
    selectedPayoutMode === 'instant'
      ? partner.payoutPrices
      : partner.payoutPricesNotInstant;

  const handleChangeCharityAmount = (event, newAmount) => {
    let value = Number(newAmount || event.target.value);

    if (value > maxCharityAmount) {
      value = maxCharityAmount;
      setShowMaxCharityAlert(true);
    } else {
      setShowMaxCharityAlert(false);
    }

    // if (value <= maxCharityAmount && sumCharityAmount + value >= 300) {

    //   value = 300 - sumCharityAmount;

    //   setShowMaxCharityAlert(true);
    // } else {
    //   setShowMaxCharityAlert(false);
    // }

    if (value > 0) {
      setCharityAmount(value);
      setValue('charityAmount', value);
    } else {
      setCharityAmount(0);
      setValue('charityAmount', null);
      setValue('charityPartner', null);
    }
  };

  const registrationImageValue = watch('registration_image');
  const formValues = watch();
  const submissionYears = watch('submissionYears');

  // const PRIMARY_MAIN = theme.palette.primary.main;

  useEffect(() => {
    const vehiclePayoutPriceCalculated = Math.round(
      vehiclePayoutPrices[selectedVehicleClass] * 1.19
    );
    setMaxCharityAmount(vehiclePayoutPriceCalculated);
    setVehiclePayoutPrice(vehiclePayoutPriceCalculated);
    setValue('vehiclePayoutPrice', vehiclePayoutPriceCalculated);
    setValue('payoutMode', selectedPayoutMode);
  }, [selectedVehicleClass, selectedPayoutMode]);

  useEffect(() => {
    setHasCharityPartner(charityPartner && charityPartner.length > 0);
  }, [charityPartner]);

  useEffect(() => {
    setValue('selectedPayoutmode', selectedPayoutMode);
  }, [selectedPayoutMode]);

  useEffect(() => {
    //set temp new verhicle for save single at next button
    setTempVehicle(formValues);
  }, [JSON.stringify(formValues)]);

  const handleDropFront = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'registration_image',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  useEffect(() => {
    if (step1Errors.registrationImageFront) {
      document.getElementById('registrationImageFront').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [step1Submitted]);

  useEffect(() => {
    //Auto choose submission year when only one year available for submission
    const newSubmissionYears =
      availableSubmissionYears.length === 1
        ? [availableSubmissionYears[0]]
        : [];
    setValue('submissionYears', newSubmissionYears);
    setFormState({ ...formState, submissionYears: newSubmissionYears });
  }, [availableSubmissionYears]);

  const onSubmit = async (vehicleData) => {
    addVehicle(vehicleData);

    setValue('charityPartner', selectedCharity);

    //add another vehicle - show verhicle overview
    document.getElementById('wrapper').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });

    enqueueSnackbar('Fahrzeug hinzugefügt', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    });
  };

  const handleSetSelectedVehicleClass = (vehicleClass) => {
    setValue('vehicleClass', vehicleClass);
    setSelectedVehicleClass(vehicleClass);
    setValue('payoutPrice', vehiclePayoutPrices[vehicleClass]);
  };
  const handleSetSelectedPayoutMode = (payoutMode) => {
    setValue('payoutMode', payoutMode);
    setSelectedPayoutMode(payoutMode);
  };

  const onChangeSubmissionYears = (year) => {
    if (availableSubmissionYears && availableSubmissionYears.length > 1) {
      const newSubmissionYears = submissionYears?.includes(year)
        ? submissionYears?.filter((name) => name !== year)
        : [...(submissionYears ?? []), year];
      setValue('submissionYears', newSubmissionYears);
      setFormState({ ...formState, submissionYears: newSubmissionYears });
    }
  };

  //only use dataform options for availableVehicleClasses for not directSubmit

  const handleSetSelectedCharity = (selectedCharity) => {
    if (selectedCharity === 'Keine Spende') {
      setCharityAmount(0);
      setValue('charityAmount', 0);
    } else if (!charityAmount) {
      //Initial default value for charity
      setCharityAmount(50);
      setValue('charityAmount', 50);
    }
    setValue('selectedCharity', selectedCharity);
    setSelectedCharity(selectedCharity);
  };

  const renderCharitySelect = () => {
    const noCharityItem = {
      logo: '',
      name: 'Keine Spende',
      shortDescription: '',
    };
    const allCharityPertner = [noCharityItem].concat(charityPartner);
    const charitiyItems = allCharityPertner.map((item, index) => {
      return (
        <Grid
          key={index}
          item
          xs={12}
          md={3}
          sx={{
            mb: 3,
          }}
        >
          <CharitySelectBox
            setSelectedCharity={handleSetSelectedCharity}
            logo={item.logo}
            name={item.name}
            isSelected={item.name === selectedCharity}
            shortDescription={item.shortDescription}
          />
        </Grid>
      );
    });

    return (
      <Grid
        container
        spacing={2}
        // justifyContent={{ xs: "le", md: "space-between" }}
        // sx={{ textAlign: { xs: "center", md: "left" } }}
      >
        {charitiyItems}
      </Grid>
    );
  };

  const renderSelectedCharity = () => {
    const selectedCharityItem = charityPartner.find(
      (e) => e.name === selectedCharity
    );
    return (
      <Card
        variant='outlined'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          background: '#FAFAFA',
          borderRadius: '7px',
          padding: '48px 24px',
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={9}
            sx={{
              mb: 3,
            }}
          >
            <Box sx={{ ml: 2 }}>
              <Typography variant='h3'>{selectedCharityItem.name}</Typography>
              <Typography>
                {ReactHtmlParser(selectedCharityItem.description)}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            sx={{
              mb: 3,
            }}
          >
            <Box>
              {' '}
              <img
                crossOrigin='anonymous'
                src={selectedCharityItem.imageUrl}
                alt={selectedCharityItem.name}
                style={{
                  objectFit: 'cover',
                  width: '100%',
                  maxHeight: '300px',
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Card>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <VehicleForm
          directSubmit={directSubmit}
          dataformOptions={dataformOptions}
          partner={partner}
          selectedVehicleClass={selectedVehicleClass}
          handleSetSelectedVehicleClass={handleSetSelectedVehicleClass}
          selectedPayoutMode={selectedPayoutMode}
          handleSetSelectedPayoutMode={handleSetSelectedPayoutMode}
        />
        {/* {partner && partner.dataformOptions.availablePayoutModes.length > 1 && (
          <>Fii</>
        )} */}

        <ImageForm
          errors={errors}
          step1Errors={step1Errors}
          step1Submitted={step1Submitted}
          control={control}
          handleDropFront={handleDropFront}
        />
        <>
          <Box
            sx={{
              marginBottom: { xs: '0px', md: '40px' },
              paddingTop: '50px',
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent={{ xs: 'center', md: 'left' }}
            >
              <Grid
                item
                xs={12}
                md={hasCharityPartner ? 12 : 12}
                sx={{ mb: 3, marginBottom: '0px', paddingTop: '0px!important' }}
              >
                <>
                  <Box sx={{ mb: 1, display: 'flex' }}>
                    <SubHeadline text='Antragsjahr' />
                  </Box>
                  <Grid
                    container
                    spacing={2}
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    {availableSubmissionYears.map((value, i) => (
                      <Grid
                        item
                        xs={12}
                        md={3}
                        sx={{
                          mb: 3,
                          marginBottom: { xs: '24px', md: '0px' },
                          paddingTop:
                            i === 1
                              ? { xs: '0px!important', md: '64px!important' }
                              : {},
                        }}
                        key={i}
                      >
                        <YearSelectBox
                          year={value}
                          isDisabled={availableSubmissionYears.length < 2}
                          submissionYears={submissionYears}
                          handleSetSelectedYear={onChangeSubmissionYears}
                        />

                        {!availableSubmissionYears.includes(value) &&
                          !availableSubmissionYears.includes(
                            actualYear.toString()
                          ) && (
                            <Alert
                              severity='info'
                              sx={{ mb: 3, marginTop: '10px' }}
                            >
                              Hinweis: Einreichungen für das aktuelle Jahr
                              können nur bis zum 30.11. erfolgen.
                            </Alert>
                          )}

                        {!availableSubmissionYears.includes(value) &&
                          !availableSubmissionYears.includes(
                            (actualYear + 1).toString()
                          ) && (
                            <Alert
                              severity='info'
                              sx={{ mb: 3, marginTop: '10px' }}
                            >
                              Hinweis: Ab dem 01.09. können Einreichungen für
                              das Folgejahr vorgenommen werden.
                            </Alert>
                          )}
                      </Grid>
                    ))}

                    {!hasCharityPartner && (
                      <Grid
                        item
                        xs={12}
                        md={4}
                        sx={{ paddingTop: '20px!important' }}
                      >
                        {submissionYears && submissionYears.length > 0 && (
                          <Box
                            sx={{
                              mb: 2,
                              textAlign: { xs: 'center', md: 'right' },
                            }}
                          >
                            <Typography variant='h5'>Sie erhalten:</Typography>
                            <Typography variant='h2' sx={{ mt: 1 }}>
                              <strong>
                                {' '}
                                {fCurrencyEuro(
                                  submissionYears.length *
                                    Math.round(
                                      vehiclePayoutPrices[
                                        selectedVehicleClass
                                      ] * 1.19
                                    ) -
                                    charityAmount
                                )}
                              </strong>
                            </Typography>
                          </Box>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </>
                {step1Submitted && step1Errors.submissionYear && (
                  <>
                    {' '}
                    <Alert severity='error' sx={{ mb: 3, marginTop: '10px' }}>
                      Bitte wählen Sie die Jahre aus für die sie die THG-Prämie
                      erhalten möchten.
                    </Alert>{' '}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
          {hasCharityPartner && (
            <Box>
              <SubHeadline text='Spenden' />

              <Typography sx={{ textAlign: 'center', mb: '32px' }}>
                Möchten Sie einen Teil der THG-Prämie spenden? Wenn ja, an
                welchen Spendenpartner?
              </Typography>

              <Box>{renderCharitySelect()}</Box>

              {selectedCharity && selectedCharity !== 'Keine Spende' && (
                <Box sx={{ mb: 2 }}>{renderSelectedCharity()}</Box>
              )}
            </Box>
          )}
        </>
        {step1Submitted && !selectedCharity && hasCharityPartner && (
          <Alert sx={{ mb: 3, marginTop: '10px' }} severity='error'>
            Bitte wählen Sie "keine Spende" oder einen Spendenpartner.
          </Alert>
        )}
        {hasCharityPartner && (
          <Grid
            item
            xs={12}
            md={12}
            sx={{
              mb: 3,
              marginBottom: { xs: '24px', md: '0px' },
              paddingTop: '0px!important',
            }}
          >
            {submissionYears && submissionYears.length > 0 && (
              <Box sx={{ mt: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    {selectedCharity && selectedCharity !== 'Keine Spende' && (
                      <Box
                        sx={{
                          borderRadius: '7px',
                          border: `1px solid #E0E0E0`,
                          p: 2,
                        }}
                      >
                        <Typography
                          variant='h6'
                          sx={{
                            marginBottom: '00px',
                            fontWeight: '500',
                            mb: 1,
                          }}
                        >
                          Wie viel möchten Sie spenden?
                        </Typography>
                        <Typography
                          variant='h6'
                          sx={{
                            fontWeight: '500',
                            mb: 0,
                            fontSize: '1rem!important',
                          }}
                        >
                          Betrag einfach auswählen
                        </Typography>

                        <ToggleButtonGroup
                          color='primary'
                          value={charityAmount}
                          exclusive
                          onChange={handleChangeCharityAmount}
                          aria-label='Platform'
                          fullWidth
                        >
                          <ToggleButton value={10}>10€</ToggleButton>
                          <ToggleButton value={20}>20€</ToggleButton>
                          <ToggleButton value={30}>30€</ToggleButton>
                          <ToggleButton value={50}>50€</ToggleButton>
                          <ToggleButton
                            value={Math.round(
                              vehiclePayoutPrices[selectedVehicleClass] * 1.19
                            )}
                          >
                            {Math.round(
                              vehiclePayoutPrices[selectedVehicleClass] * 1.19
                            )}
                            €
                          </ToggleButton>
                          {/* <ToggleButton value={250}>250€</ToggleButton> */}
                        </ToggleButtonGroup>
                        <Typography
                          variant='h6'
                          sx={{
                            marginBottom: '00px',
                            fontWeight: '500',
                            mb: -1,
                            mt: 2,
                            fontSize: '1rem!important',
                          }}
                        >
                          Oder Spendenbetrag frei eingeben
                        </Typography>

                        <TextField
                          sx={{ mt: 2, mb: '0px!important' }}
                          type='number'
                          id='outlined-basic'
                          label='Spendenbetrag'
                          fullWidth={isMobileView}
                          focused
                          InputProps={{
                            style: { fontWeight: 700 },
                            inputProps: {
                              max: 300,
                            },

                            endAdornment: (
                              <InputAdornment position='end'>€</InputAdornment>
                            ),
                          }}
                          value={charityAmount}
                          onChange={handleChangeCharityAmount}
                        />

                        {showMaxCharityAlert && (
                          <Alert
                            sx={{ mb: 3, marginTop: '10px' }}
                            severity='error'
                          >
                            {` Sie können für diese Fahrzeugklasse  ${fNumberFull(
                              vehiclePayoutPrice
                            )},-€ als Maximalbetrag spenden.`}
                          </Alert>
                        )}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {submissionYears && submissionYears.length > 0 && (
                      <Box
                        sx={{ mb: 2, textAlign: { xs: 'center', md: 'right' } }}
                      >
                        {selectedCharity &&
                          selectedCharity !== 'Keine Spende' &&
                          charityAmount > 0 && (
                            <Box sx={{ color: theme.palette.primary.main }}>
                              {' '}
                              <Typography variant='h5'>Ihre Spende:</Typography>
                              <Typography variant='h2' sx={{ mt: 1 }}>
                                <strong>{fCurrencyEuro(charityAmount)}</strong>
                              </Typography>
                            </Box>
                          )}

                        <Typography variant='h5'>Sie erhalten:</Typography>
                        <Typography variant='h2' sx={{ mt: 1 }}>
                          <strong>
                            {' '}
                            {fCurrencyEuro(
                              submissionYears.length *
                                Math.round(
                                  vehiclePayoutPrices[selectedVehicleClass] *
                                    1.19
                                ) -
                                charityAmount
                            )}
                          </strong>
                        </Typography>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        )}
        {dataformOptions.multipleVehicles && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                paddingTop: { xs: '0px', md: '16px' },
                paddingBottom: { xs: '32px', md: '32px' },
                justifyContent: 'center',
              }}
            >
              {formState.vehicles.length < 9 ? (
                <Button
                  disabled={
                    (submissionYears && submissionYears.length === 0) ||
                    !submissionYears ||
                    !registrationImageValue ||
                    (charityAmount > 0 && !selectedCharity)
                  }
                  variant='outlined'
                  size='large'
                  onClick={handleSubmit(onSubmit)}
                >
                  <AddCircleIcon sx={{ marginRight: '5px' }} /> Weiteres
                  Fahrzeug
                </Button>
              ) : (
                <Alert severity='warning' sx={{ mb: 3, marginTop: '10px' }}>
                  Sie haben die maximale Anzahl an Fahrzeugen für eine
                  Übermittlung erreicht. Falls Sie weitere Fahrzeuge hinzufügen
                  möchten, senden Sie das Formular ab und benutzen Sie es
                  erneut.
                </Alert>
              )}
            </Box>
          </>
        )}
      </FormProvider>
    </Box>
  );
};

export default FormVehicle;
