import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'preact/hooks';
import { Banner } from './Banner';
import { TextBox } from './TextBox';
import { ContactBox } from './ContactBox';

import { useTheme } from '@mui/material/styles';
import { SubHeadline } from '../../components/SubHeadline';

export const ServiceUnavailablePage = ({ partner, actualYear }) => {
  useEffect(() => {
    document.getElementById('wrapper').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }, []);
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const { enqueueSnackbar } = useSnackbar();

  const headLinePrice = Math.round(partner.payoutPrices['M1'] * 1.19);

  const handleNext = () => null;
  return (
    <Box
      sx={{
        position: 'relative',
        marginBottom: '40px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 1,
          mb: 1,
          textAlign: 'center',
          mb: 4,
          mt: 4,
          width: '100%',
        }}
      >
        <Typography
          variant='h3'
          sx={{
            fontWeight: 400,
            textTransform: 'uppercase',
            fontSize: '1.7rem!important',
            letterSpacing: '-0.5px',
            textTransform: 'uppercase',
            mb: '5px',
          }}
        >
          DAS AKTUELLE THG-QUOTENJAHR IST ABGESCHLOSSEN!
        </Typography>
      </Box>

      <Banner actualYear={actualYear - 1} partner={partner} />
      <TextBox
        headline='WARTEN AUF DEN NEUSTART'
        actualYear={actualYear}
        partner={partner}
      />

      {/* <ContactBox email={partner.email} phone={partner.phone} /> */}
    </Box>
  );
};
