import { Button } from '@mui/material';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import getBankNameFromIban from '../../utils/BicChecker';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'preact/hooks';
import { Fragment } from 'preact';
import ReactGA from 'react-ga4';
import { ContactInformationForm } from './ContactInformationForm';
import { BankDataForm } from './BankDataForm';
const ibantools = require('ibantools');
import { isValidIBAN } from 'ibantools-germany';

export const FormStepTwo = ({
  formState,
  setFormState,
  handleNext,
  handleBack,
  inviteEmail,
  partner,
}) => {
  const {
    watch,
    control,
    handleSubmit,
    setError,
    setValue,
    unregister,
    clearErrors,
    trigger,
    formState: { errors, touchedFields, dirtyFields, isValid },
  } = useForm({
    defaultValues: formState,
    criteriaMode: 'all',
    shouldFocusError: true,
    mode: 'submit',
    reValidateMode: 'onChange',
  });
  const formValues = watch();
  const [bankData, setBankData] = useState(false);
  const [successFields, setSuccessFields] = useState([]);
  const [errorFields, setErrorFields] = useState([]);
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (partner && partner.name === 'THG-QUOTENPORTAL') {
      ReactGA.event({
        category: 'Dataform',
        action: 'open_step2',
      });
    }
  }, []);

  useEffect(() => {
    if (errors.iban) {
      setBankData(false);
    } else if (formValues.iban && formValues.iban.trim().length >= 22) {
      getIbanInfo(formValues.iban);
    }
  }, [formValues.iban]);

  const scrollToTop = () => {
    document.getElementById('wrapper').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };

  const getIbanInfo = async (iban) => {
    try {
      const bankData = getBankNameFromIban(iban);
      if (bankData) {
        setBankData(bankData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleProcessTypeChange = (event) => {
    setValue('type', event.target.value);
    const hasSalesTax = event.target.value !== 'b2c';
    if (event.target.value === 'b2c') {
      unregister('companyName');
      unregister('taxId');
    }
    setValue('salesTax', hasSalesTax);
    setFormState({
      ...formState,
      ...formValues,
      type: event.target.value,
      salesTax: hasSalesTax,
    });
  };

  const handleSalesTaxChange = (event) => {
    const hasSalesTax = event.target.value === 'true';
    setValue('salesTax', hasSalesTax);
    if (!hasSalesTax) {
      unregister('taxId');
    }

    setFormState({ ...formState, ...formValues, salesTax: hasSalesTax });
  };

  const onSubmit = async (data) => {
    // Trigger validation for all fields
    const isValidForm = await trigger();

    if (isValidForm) {
      // Only proceed to the next step if the form is valid
      setFormState({ ...data });
      handleNext();
    } else {
      // Handle the case where the form is invalid (e.g., scroll to the first error)
      console.log('Form is invalid, cannot proceed');
      scrollToTop(); // Ensure the user sees the error message
    }
  };

  const handleIbanChange = (event) => {
    const text = event.target.value.replace(/\s/g, '');

    const formattedText = ibantools.friendlyFormatIBAN(text);

    setValue('iban', formattedText);
    trigger('iban');
  };

  const handleTaxIdChange = (event) => {
    const text = event.target.value.toUpperCase().replace(/\s/g, '');
    const formattedText = text
      .replace(/[-.●]/g, '')
      .replace(/[^A-Z0-9/]/g, '')
      .trim();

    setFormState({
      ...formState,
      ...formValues,
      taxId: formattedText,
    });
    setValue('taxId', formattedText);

    trigger('taxId');
  };

  const phoneFieldStyle = !errors.phone
    ? {
        marginBottom: '20px',
        '.react-tel-input .form-control:focus': {
          borderColor: PRIMARY_MAIN,
          boxShadow: `0 0 0 1px ${PRIMARY_MAIN}`,
        },
        '.react-tel-input .form-control': {
          padding: '18px 14px 18px 58px;',
          width: '100%',
        },
        '.react-tel-input .form-control:focus div::before': {
          color: PRIMARY_MAIN,
        },
      }
    : {
        marginBottom: '20px',
        '.react-tel-input .form-control:focus': {
          borderColor: PRIMARY_MAIN,
          boxShadow: `0 0 0 1px ${PRIMARY_MAIN}`,
        },
        '.react-tel-input .form-control': {
          padding: '18px 14px 18px 58px;',
          width: '100%',
          borderColor: '#d32f2f',
        },
        '.react-tel-input .form-control:focus div::before': {
          color: PRIMARY_MAIN,
        },
      };

  return (
    <Box sx={{ padding: { cs: '0px 37px' } }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <ContactInformationForm
            handleProcessTypeChange={handleProcessTypeChange}
            formState={formState}
            control={control}
            handleSalesTaxChange={handleSalesTaxChange}
            errors={errors}
            dirtyFields={dirtyFields}
            formValues={formValues}
            successFields={successFields}
            handleTaxIdChange={handleTaxIdChange}
            inviteEmail={inviteEmail}
            phoneFieldStyle={phoneFieldStyle}
          />
          <BankDataForm
            errors={errors}
            dirtyFields={dirtyFields}
            formValues={formValues}
            control={control}
            touchedFields={touchedFields}
            successFields={successFields}
            errorFields={errorFields}
            handleIbanChange={handleIbanChange}
            bankData={bankData}
          />
          <Fragment>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: { xs: '40px 0px 40px 0px', md: '40px 0px' },
              }}
            >
              <Button
                size='large'
                variant='outlined'
                onClick={() => handleBack(true)}
                sx={{ mr: 1, textTransform: 'none' }}
              >
                Vorgang neu starten
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />

              <Button
                size='large'
                variant='contained'
                sx={{
                  color: '#FFF',
                  boxShadow: 'none!important',
                }}
                onClick={handleSubmit(onSubmit)}
              >
                weiter
              </Button>
            </Box>
          </Fragment>
        </>
      </form>
    </Box>
  );
};
