import { Grid, Accordion, Typography, Box } from '@mui/material';
import { NoPreview } from '../../components/upload';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ThankYouImage from '../../static/ThankYou.svg';
import { fCurrencyEuro } from '../../utils/formatNumber';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SubHeadline } from '../../components/SubHeadline';

export const VehicleSummary = ({ formState, partner, theme, formValues }) => {
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));

  const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };

  const labelMapping = {
    M1: 'E-PKW',
    LXe: 'E-Zweirad',
    N1: 'E-Nutzfahrzeug',
    N2: 'E-Nutzfahrzeug',
    N3: 'E-Nutzfahrzeug',
    M3: 'E-Bus',
  };

  const subLabelMapping = {
    M1: 'M1',
    LXe: 'L3e, L4e, L5e, L7e',
    N1: 'N1 bis 3,5t',
    N2: 'N2 ab 3,5t bis 12t',
    N3: 'N3 ab 12t',
    M3: 'M3',
  };

  return (
    <Grid item xs={12} md={12} sx={{ pt: '32px!important' }}>
      {' '}
      <Box sx={{ mb: 1, display: 'flex' }}>
        <SubHeadline
          text={`${formState.vehicles.length === 1 ? 'Fahrzeug' : 'Fahrzeuge'}`}
        />
      </Box>
      {formState.vehicles.map((vehicle, index) => {
        const hasCharity = vehicle.charityAmount;
        const charityAmount = vehicle.charityAmount || 0;
        return (
          <Box>
            <Accordion
              sx={{
                boxShadow: 'none',
              }}
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color='primary' />}
                aria-controls='panel1a-content'
                id='panel1a-header'
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '4px',
                  boxShadow: 'none',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    backgroundImage: hasCharity
                      ? `url(${ThankYouImage})`
                      : 'none',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: isMobileView
                      ? 'right top'
                      : 'right 20px top',
                  }}
                >
                  <Box sx={{ maxWidth: '80%' }}>
                    <Typography sx={{ marginLeft: '10px' }}>
                      <strong>Fahrzeugklasse:</strong>{' '}
                      {`${labelMapping[vehicle.vehicleClass]} (${
                        subLabelMapping[vehicle.vehicleClass]
                      })`}
                    </Typography>
                    <Typography sx={{ marginLeft: '10px' }}>
                      <strong>{`Auszahlungsbetrag: `}</strong>

                      {fCurrencyEuro(
                        vehicle.vehiclePayoutPrice - charityAmount
                      )}
                      {formValues.type === 'b2b' && formValues.salesTax && (
                        <>{' (inkl. MwSt.)'}</>
                      )}
                    </Typography>

                    {charityAmount ? (
                      <Box>
                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>{`Spendenbetrag: `}</strong>{' '}
                          {fCurrencyEuro(charityAmount)}
                          {vehicle.charityAmount && <span> *</span>}
                        </Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {vehicle.selectedCharity ? (
                      <Typography sx={{ marginLeft: '10px' }}>
                        <strong>{`Spendenpartner: `}</strong>{' '}
                        {vehicle.selectedCharity}
                      </Typography>
                    ) : (
                      <></>
                    )}
                    <Typography sx={{ marginLeft: '10px' }}>
                      <strong>{`Abrechnungszeitraum: `}</strong>

                      {`${vehicle.submissionYears.join(', ')}`}
                    </Typography>

                    <Typography sx={{ marginLeft: '10px' }}>
                      <strong>{`Auszahlungstyp: `}</strong>{' '}
                      {vehicle.payoutMode === 'instant'
                        ? 'Sofortauszahlung'
                        : 'Auszahlung nach Bestätigung durch Umweltbundesamt'}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  overflow: 'hidden',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '4px',
                  boxShadow: 'none',
                }}
              >
                <Grid
                  container
                  spacing={5}
                  justifyContent={{
                    xs: 'center',
                    md: 'space-between',
                  }}
                  sx={{
                    textAlign: { xs: 'center', md: 'left' },
                    marginBottom: '20px',
                  }}
                >
                  {vehicle.registration_image && (
                    <Grid item xs={12} md={12}>
                      {getFileExtension(vehicle.registration_image.name) ===
                        'heic' ||
                      getFileExtension(vehicle.registration_image.name) ===
                        'pdf' ? (
                        <NoPreview fileName={vehicle.registration_image.name} />
                      ) : (
                        <img
                          src={`${window.URL.createObjectURL(
                            vehicle.registration_image
                          )}`}
                          style='width: 100%; object-fit: contain; max-height: 300px'
                        />
                      )}
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
            {vehicle.charityAmount ? (
              <Typography sx={{ color: '#aaa', fontSize: '14px', ml: 2 }}>
                * Bitte beachten Sie, dass wir keine Spendenquittung ausstellen
                können.
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        );
      })}
    </Grid>
  );
};
