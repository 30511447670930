import { useState } from 'react';
// @mui
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import config from '../config';
import AxaLogo from '../static/axa_logo.svg';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  Box,
  Button,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  FormGroup,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { fNumberFull } from '../utils/formatNumber';
// hooks

// ----------------------------------------------------------------------

export default function NewUserInvite({
  platformPartner,
  dataformToken,
  partner,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [orgaNumber, setOrgaNumber] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);

  const [salutation, setSalutation] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);

  const onChangeFirstname = (event) => {
    setFirstname(event.target.value);
  };

  const onChangeLastname = (event) => {
    setLastname(event.target.value);
  };

  const onChangeOrgaNumber = (event) => {
    setOrgaNumber(event.target.value);
  };

  const onChangeSalutation = (event) => {
    setSalutation(event.target.value);
  };

  const onChangeEmail = (event) => {
    const emailAdress = event.target.value;
    const validEmail = validateEmail(emailAdress);
    setEmail(event.target.value);

    if (validEmail) {
      setEmailInvalid(false);
    } else {
      // set helper text
      setEmailInvalid(true);
    }
  };

  const onChangeAcceptTerms = (event) => {
    const value = event.target.checked;
    setAcceptTerms(value);
  };

  const inviteNewUser = async () => {
    setLoading(true);
    const data = {
      salutation,
      firstname,
      lastname,
      email,
      dataformToken,
      orgaNumber,
    };

    const apiHost =
      platformPartner === 'Greenfactory'
        ? config.API_HOST_GREENFACTORY
        : config.API_HOST;

    const response = await fetch(`${apiHost}/v1/dataform/invite/thg`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${dataformToken}`,
      }),
    });
    if (response && response.status === 201) {
      enqueueSnackbar('Einladung erfolgreich versendet', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } else {
      enqueueSnackbar('Fehler beim Versenden der Einladung', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    }
    // Reset input fields
    setSalutation('');
    setFirstname('');
    setLastname('');
    setEmail('');
    setOrgaNumber('');
    setLoading(false);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  return (
    <Box
      sx={{
        mt: 4,
        mb: 4,
        maxWidth: '650px',
        margin: '0 auto',
        textAlign: 'center',
      }}
    >
      <Box>
        {/* <Typography variant={'h6'}>Einladungs-Email versenden</Typography> */}
      </Box>
      <Box
        sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'column' } }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 6,
          }}
        >
          <img height='60' src={AxaLogo} />
        </Box>
        <Typography variant='h4' sx={{ mb: 2 }}>
          THG-Prämie für Ihre Kund:innen mit E-Fahrzeug
        </Typography>
        <Typography sx={{ fontSize: '0.95rem', mb: 6 }}>
          Geben Sie jetzt hier die Daten Ihrer Kund:innen ein, dieser erhält
          darauf eine E-Mail mit Einladungslink. Über diesen Einladungslink kann
          mit wenigen Angaben direkt die THG-Quoten-Prämie beantragt werden. Die
          Auszahlung der Prämie erfolgt nach Prüfung innerhalb von 3-5
          Werktagen.
        </Typography>
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
            marginTop: '15px',
            marginLeft: '0px',
            textAlign: 'left',
          }}
          size='large'
        >
          <InputLabel>Anrede</InputLabel>

          <Select
            value={salutation}
            labelId='select-label'
            id='select'
            label='Anrede'
            onChange={onChangeSalutation}
          >
            <MenuItem value={'mr'}>Herr</MenuItem>
            <MenuItem value={'mrs'}>Frau</MenuItem>
          </Select>
        </FormControl>
        <TextField
          value={firstname}
          sx={{ mr: 1 }}
          variant='outlined'
          label={'Vorname'}
          margin='normal'
          placeholder='Vorname'
          onChange={onChangeFirstname}
        />
        <TextField
          value={lastname}
          sx={{ mr: 1 }}
          variant='outlined'
          margin='normal'
          label={'Nachname'}
          placeholder='Nachname'
          onChange={onChangeLastname}
        />
        <TextField
          value={email}
          sx={{ mr: 1, flexGrow: 1 }}
          error={emailInvalid}
          id='outlined-error-helper-text'
          helperText={emailInvalid ? 'Ungültige E-Mail-Adresse' : ''}
          type='email'
          variant='outlined'
          label={'E-Mail-Adresse'}
          margin='normal'
          placeholder='E-Mail-Adresse'
          onChange={onChangeEmail}
        />
        <TextField
          value={orgaNumber}
          sx={{ mr: 1 }}
          variant='outlined'
          margin='normal'
          placeholder='Organummer'
          label={'Organummer'}
          onChange={onChangeOrgaNumber}
        />
        <Box
          sx={{
            paddingTop: { xs: '10px!important', md: '0px!important' },
          }}
        >
          <FormGroup>
            <FormControlLabel
              sx={{ margin: '10px 0px' }}
              // className={!termsError ? 'error' : ''}
              control={
                <Checkbox
                  sx={{ padding: '10px', paddingRight: '20px' }}
                  value={acceptTerms}
                  onChange={onChangeAcceptTerms}
                />
              }
              label={
                <>
                  Es liegt eine gültige und vom Kunden unterzeichnete{' '}
                  <Link
                    href={`${config.API_HOST_GREENFACTORY}/static/pdf/202307_GREENfactory_EWE.pdf`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Einwilligungserklärung
                  </Link>{' '}
                  vor.
                </>
              }
            />
          </FormGroup>
        </Box>
        <Button
          disabled={
            !salutation ||
            !firstname ||
            !lastname ||
            !email ||
            !orgaNumber ||
            emailInvalid ||
            !acceptTerms ||
            loading
          }
          sx={{
            marginTop: '15px',
            marginBottom: '8px',
            height: '56px',
            textTransform: 'none',
            color: '#FFF',
          }}
          variant='contained'
          onClick={inviteNewUser}
        >
          Einladung senden
        </Button>
      </Box>
    </Box>
  );
}
