import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'preact/hooks';
import { CheckItem } from './CheckItem';
import { CTAButton } from './CTAButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import config from '../../config';

export const Banner = ({ handleNext, partner }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));
  const platformPartner = partner.platformPartner;
  const headLinePrice = Math.round(partner.payoutPrices['M1'] * 1.19);

  const apiHost =
    platformPartner === 'Greenfactory'
      ? config.API_HOST_GREENFACTORY
      : config.API_HOST;
  return (
    <Box
      sx={{
        display: 'flex',
        padding: '40px',
        minHeight: '580px',
        position: 'relative',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        bgcolor: 'background.neutral',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${apiHost}/static/assets/banner.jpg)`,
        borderRadius: '8px',
        mb: 4,
        mt: 4,
      }}
    >
      <Box
        sx={{
          background: `linear-gradient(rgba(255,255,255,.9),rgba(255,255,255,.9)) ${color}`,
          minWidth: '300px',
          opacity: 0.9,
          p: 2,
          position: 'absolute',
          right: isMobileView ? '15px' : '80px',
          top: '80px',
          borderRadius: '8px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', opacity: 1 }}>
          <CheckItem
            headline={`Ab ${headLinePrice} EUR per Sofortauszahlung`}
            text='in wenigen Schritten'
          />
          <CheckItem
            headline='Zuverlässig und Schnell'
            text='in 3 – 5 Werktagen auf Ihrem Konto'
          />

          <CheckItem
            headline='Transparent und Digital'
            text='ohne Registrierung oder Nutzerkonto'
          />
          <CheckItem
            headline='Bis zu 10 Fahrzeuge'
            text='gleichzeitig einreichen und vermarkten'
          />

          <CTAButton handleNext={handleNext} />
        </Box>
      </Box>
    </Box>
  );
};
